import firebase from "firebase/app"
import "firebase/firestore"
import React, { useEffect, useState } from "react"
import {
  Button,
  Form,
  FormGroup,
  Label,
  Row,
  Col,
  Input,
  Spinner,
  Container,
  Table,
} from "reactstrap"
import TimePicker from "react-time-picker"
import { Aula } from "models/aula"
import {
  getAulaById,
  updateAula,
} from "services/aulas/aulas_services"
import AdicionarAlunos from "components/modals/adicionar_alunos_modal"
import { useSelector } from "react-redux"
import { selecttreinadores } from "store/treinadores/treinadores_reducer"
import { aulasCollection, convertCamps, niveis, recepRole } from "services/consts"
import InfoAlunosModal from "components/modals/info_alunos_modal"
import {
  retornaCamposIndisponíveisNaHora, retornaCamposIndisponíveisNaHoraENDP,
} from "services/reservas/reservas_services"
import { ProcuraReserva } from "models/reserva"
import { useParams } from "react-router-dom/cjs/react-router-dom.min"
import DatePicker from "react-date-picker"
import { selectCurrentUserAuth } from "store/middlewarerotas/CurrentUserAuth"



function VerAulasScreen(props) {
  let { id } = useParams()
  const userRole = useSelector(selectCurrentUserAuth)

  const [renderas, setRenderas] = useState(true)
  var treinadores = useSelector(selecttreinadores)
  const [nomeDaAula, setNomeDaAula] = useState("")
  const [horaInicial, setHoraInicial] = useState(null)
  const [horaFinal, setHoraFinal] = useState(null)
  const [startDate, setStartDate] = useState(null)
  const [endDate, setEndDate] = useState(null)
  const [campoEscolhido, setCampoEscolhido] = useState(null)
  const [campoDaAula, setCampoDaAula] = useState(null)
  const [nivel, setNivel] = useState(null)
  const [professor, setProfessor] = useState(null)
  const [isLoading, setIsLoading] = useState(false)
  const [diaDaSemana, setDiaDaSemana] = useState(0)
  const [isAtiva, setIsAtiva] = useState(null)
  const [tipoDeAula, setTipoDeAula] = useState(null)
  const [alunos, setAlunos] = useState([])
  const [alunoData, setAlunoData] = useState(null)
  const [alunosData, setAlunosData] = useState(null)
  const [alunosPax, setAlunosPax] = useState(null)
  const [notas, setNotas] = useState("")
  const [campos, setCampos] = useState([])

  const [aula, setAula] = useState(null)

  const [alunosDataJsonArr, setAlunosDataJsonArr] = useState([])
  const [isCamposIndisLoading, setIsCamposIndisLoading] = useState(false)

  async function getTefById() {
    try {
      return firebase
        .firestore()
        .collection(aulasCollection)
        .doc(id)
        .onSnapshot(snap => {
          var res = snap.data()
          setNomeDaAula(res.nome)
          
          setStartDate(res.diaInicial.toDate())
          setEndDate(res.diaFinal.toDate())
          setCampoEscolhido(res.campos)
          setCampoDaAula(res.campos)
          setNivel(res.nivel)
          setProfessor(res.professor)
          setIsLoading(false)
          setDiaDaSemana(res.weekDay)
          setIsAtiva(res.isAtiva)
          setTipoDeAula(res.tipo)

          setAlunoData(res.alunoData)
          setAlunos(res.alunos)
          if (typeof res.alunoData != "undefined") {
            var alunosDataJsonAuxssss = Object.entries(res.alunoData)
            setAlunosDataJsonArr(alunosDataJsonAuxssss)
          }

          /* setAlunosData(res.alunosData) */
          /*  setAlunosPax(res.alunosPAX) */
          setCampos(res.campos)

          setAula(res)
          if (typeof res.notas != "undefined") {
            setNotas(res.notas)
          } else {
            setNotas("")
          }
          setHoraFinal(res.horaFinal)
          setHoraInicial(res.horaInicial)

          rendera()
          setRenderas(true)
        })
    } catch (error) {
      console.error(error)
      return null
    }
  }

  const handleChange = event => {
    if (event.target.checked) {
      
      alert("Adicione um campo à aula")
    } else {
      setCampos([])
      setCamposDisp([])
      setCampoEscolhido([]) 
      alert("Ao alterar o estado da aula para inativo, o campo associado à aula será removido da mesma ")
    }
    setIsAtiva(current => !current)
  }

  const [camposDisp, setCamposDisp] = useState([])
  const numeroDeCampos = 6

  var weekday = new Array(7)
  weekday[0] = " "
  weekday[1] = "Segunda"
  weekday[2] = "Terça"
  weekday[3] = "Quarta"
  weekday[4] = "Quinta"
  weekday[5] = "Sexta"
  weekday[6] = "Sábado"
  weekday[7] = "Domingo"

  /*   const [camposDisponiv, setCamposDisponiv] = useState([]) */

  function minsDiffs(horaInicial, horaFinal, weekDay) {
    var hi = new Date()
    var hf = new Date()
    var himins = horaInicial.substring(3, 5)
    var hihour = horaInicial.substring(0, 2)
    var hfmins = horaFinal.substring(3, 5)
    var hfhour = horaFinal.substring(0, 2)
    hi.setHours(hihour)
    hi.setMinutes(himins)
    hf.setHours(hfhour)
    hf.setMinutes(hfmins)

    var diffMs = hf - hi
    var duracao = Math.round(diffMs / 60000) // minutes

    var today = hi.getDay()
    if (today == 0) {
      today = 7
    }

    if (today > weekDay) {
      weekDay = weekDay + 7
    }

    var daysDiff = weekDay - today
    var dia = hi.getDate()
    var mes = hi.getMonth()
    var ano = hi.getFullYear()
    var checkDate = new Date(ano, mes, dia + daysDiff, hihour, himins)

    return [duracao, checkDate]
  }

  function removeCamposRepetidos(campoosInsdis) {
    var listaDeCampos = []
    var novaLista = []
    for (var i = 1; i <= numeroDeCampos; i++) {
      var stringAux = "Campo " + i

      if (campoosInsdis.includes(stringAux) == false) {
        listaDeCampos.push(stringAux)
      }
    }

    setCamposDisp(listaDeCampos)
  }

  const removeCamposEsc = value => {
    setCampoEscolhido(campoEscolhido.filter(item => item !== value))
  }

  useEffect(async () => {
    await getTefById()
  }, [])


  const stylesTable = {
    "--bs-table-bg": "#ececf1",
  }


  const rendera = () => {
    if (renderas == false) {
      return null
    } else
      return (
        <React.Fragment>
          
            <div className="page-content">
              <Container style={{ padding: "40px" }} fluid>
                <Form>
                  <FormGroup check>
                    <Row>
                    <Col md={10}>
                    <Label check>
                      
                      <Input
                        type="checkbox"
                        checked={isAtiva}
                        value={isAtiva}
                        onChange={handleChange}
                        id="isAulaAtiva"
                        name="subscribe"
                      />{" "}
                      Ativa
                    </Label>
                    </Col>
                    <Col md={2}>
                    <Button color="primary" onClick={() => {
                      setCampos([])
                      setCamposDisp([])
                      setCampoEscolhido([]) 
                    }}>Remover Campo</Button>
                    </Col>
                    </Row>
                  </FormGroup>
                  <FormGroup>
                
                    <Row>
                      <Col md={3}>
                        <p> Tipo </p>
                      </Col>
                      <Col md={9}>
                        <h5 style={{ fontWeight: "bold" }}>{tipoDeAula}</h5>
                      </Col>
                     
                    </Row>
                  </FormGroup>
                  <FormGroup>
                    <Row>
                      <Col md={3}>
                        <p>Nome da Aula</p>
                      </Col>
                      <Col md={9}>
                        <Input
                          value={nomeDaAula}
                          onChange={e => {
                            setNomeDaAula(e.target.value)
                          }}
                          type="text"
                          name="aulaInput"
                          id="aulaInput"
                        />
                      </Col>
                    </Row>
                  </FormGroup>
                  <FormGroup hidden={tipoDeAula == "Experimental" ? false : true} >
                    <Row>
                      <Col md={3}>
                        <p>Expira em</p>
                      </Col>
                      <Col md={9}>
                        <DatePicker
                        onChange={(e) => {
                          setEndDate(e)
                          setCampos([])
                          setCamposDisp([])
                          setCampoEscolhido([]) 
                        }}
                          
                          value={endDate}
                          disableClock={true}
                        ></DatePicker>
                      </Col>
                    </Row>
                  </FormGroup>
                  <FormGroup>
                    <Row>
                      <Col md={3}>
                        <p>Hora Inicial</p>
                      </Col>
                      <Col md={9}>
                        <TimePicker
                          onChange={(e) => {
                            setHoraInicial(e)
                            setCampos([])
                            setCamposDisp([])
                            setCampoEscolhido([]) 
                          }}
                          value={horaInicial}
                          disableClock={true}
                        ></TimePicker>
                      </Col>
                    </Row>
                  </FormGroup>
                  <Row>
                    <Col md={3}>
                      <p>Hora Final</p>
                    </Col>
                    <Col md={9}>
                      <TimePicker
                        onChange={(e) => {
                          setHoraFinal(e)
                          setCampos([])
                          setCamposDisp([])
                          setCampoEscolhido([]) 
                        }}
                        value={horaFinal}
                        disableClock={true}
                      ></TimePicker>
                    </Col>
                  </Row>
                  <FormGroup>
                    <Row>
                      <Col md={3}>
                        <p>Dia da semana</p>
                      </Col>
                      <Col md={9}>
                        <Input
                          value={weekday[diaDaSemana]}
                          onChange={e => {
                            setDiaDaSemana(
                              document.getElementById("weekdaySelect")
                                .selectedIndex
                            )
                            setCampos([])
                            setCamposDisp([])
                            setCampoEscolhido([]) 
                          
                          }}
                          type="select"
                          name="select"
                          id="weekdaySelect"
                        >
                          {weekday.map((element, index) => {
                            return <option key={index}>{element}</option>
                          })}
                        </Input>
                      </Col>
                    </Row>
                  </FormGroup>
                  <Row>
                    <Col md={3}>
                      <p>Localização</p>
                    </Col>
                    <Col md={9}>
                      <FormGroup>
                        <Input type="select" name="select" id="localSelect">
                          <option>Great Padel Vila Verde</option>
                        </Input>
                      </FormGroup>
                    </Col>
                  </Row>
                  <FormGroup>
                    <Row>
                      <Col md={3}>
                        <p>Professor</p>
                      </Col>
                      <Col md={9}>
                        <Input
                          onChange={e => {
                            
                            setProfessor(e.target.value)
                          }}
                          value={professor}
                          type="select"
                          name="select"
                          id="professorInput"
                        >
                          {treinadores.map((elem, index) => {
                            return <option key={index}>{elem.nome}</option>
                          })}
                        </Input>
                      </Col>
                    </Row>
                  </FormGroup>
                  <FormGroup>
                    <Row>
                      <Col md={3}>
                        <p>Nivel</p>
                      </Col>
                      <Col md={9}>
                        <Input
                          value={nivel}
                          onChange={e => {
                            setNivel(e.target.value)
                          }}
                          type="select"
                          name="select"
                          id="nivelSelect"
                        >
                          {niveis.map((elem, index) => {
                            return <option key={index}>{elem}</option>
                          })}
                        </Input>
                      </Col>
                    </Row>
                  </FormGroup>
                  <FormGroup>
                   {campos.length != 0 ? <Row style={{ paddingTop: "20px" }}>
                      <p style={{ fontWeight: "bold" }}>
                        Campo da aula: {convertCamps[campos]}
                      </p>
                    </Row>  : <Row style={{ paddingTop: "20px" }}>
                      <p style={{ fontWeight: "bold", fontSize: "26px", color: "red" }}>
                        Nenhum campo escolhido
                      </p>
                    </Row>  }
                    {isCamposIndisLoading ? <Spinner color="primary"></Spinner> :
                    <Button
                      style={{ marginBottom: "20px" }}
                      color="primary"
                      onClick={async () => {
                        setIsCamposIndisLoading(true)
                        const reserva = minsDiffs(
                          horaInicial,
                          horaFinal,
                          diaDaSemana
                        )
                        var procuraReserva = new ProcuraReserva()
                        procuraReserva.duracaoDaReserva = reserva[0]
                        procuraReserva.horaDaReserva = reserva[1]
                        procuraReserva.localizacao = "Great Padel Vila Verde"

                        if (tipoDeAula == "Experimental") {
                          var himinss = horaInicial.substring(3, 5)
                          var hihours = horaInicial.substring(0, 2)
                          procuraReserva.duracaoDaReserva = reserva[0]
                          procuraReserva.horaDaReserva = new Date(endDate.getFullYear(), endDate.getMonth() , endDate.getDate(), hihours, himinss);
                          procuraReserva.localizacao = "Great Padel Vila Verde"
                        }
                        console.log(procuraReserva.horaDaReserva)
                        await retornaCamposIndisponíveisNaHoraENDP(
                          procuraReserva
                        ).then((value) => {
                          
                          removeCamposRepetidos(value['Ocupados'])  
                        })
                        setIsCamposIndisLoading(false)
                      }}
                    >
                      Mostrar campos disponíveis
                    </Button>}
                    <Row>
                      {camposDisp.map((value, index) => {
                        return (
                          <Col
                            key={index}
                            md={4}
                            style={{ paddingBottom: "10px" }}
                          >
                            <Button
                              outline={
                                campoEscolhido != null ?
                                campoEscolhido.includes(value) ? false : true : true
                              }
                              color="primary"
                              onClick={() => {
                                if( campoEscolhido == null) {
                                  setCampoEscolhido([value])
                                }
                                else if (!campoEscolhido.includes(value)) {
                                  setCampoEscolhido([value])
                                } else {
                                  setCampoEscolhido(null)
                                }
                              }}
                            >
                              {convertCamps[value]}
                            </Button>
                          </Col>
                        )
                      })}
                    </Row>
                  </FormGroup>


                  {/* ALUNOS */}
                  <FormGroup  className="mt-5">
                    <Row hidden={userRole == recepRole}>
                      <Col  className="d-flex align-items-center">
                        <h4>Alunos</h4>
                        <AdicionarAlunos  aulaId={id} aula={aula} />
                      </Col>
                    </Row>
                    <Row className="pt-2">
                      <Table bordered striped responsive className="myList" style={stylesTable}>
                        <thead>
                          <tr>
                            <th>Nome</th>
                            <th>Email</th>
                            <th>Telefone</th>
                            <th>Ações</th>
                          </tr>
                        </thead>
                        <tbody>
                            {alunosDataJsonArr == 0 ? (
                            <p>Nenhum aluno inscrito</p>
                          ) : (
                            alunosDataJsonArr.map((aluno, index) => {
                              return (
                                <tr
                                  style={{ padding: "10px" }}
                                  key={index + aluno}
                                >
                                 <td > {aluno[1].nome}</td>
                                  <td >
                                    {aluno[1].academia == "Sim" ? (
                                      typeof aluno[1].proxPag == "undefined" ||
                                      aluno[1].proxPag.toDate() < new Date() ? (
                                        <p style={{ color: "red" }}>{aluno[0]}</p>
                                      ) : (
                                        <p style={{ color: "green" }}>{aluno[0]}</p>
                                      )
                                    ) : (
                                      <p> {aluno[0]}</p>
                                    )}
                                  </td>
                                  <td >
                                    {aluno[1].tel}
                                  </td> 
                                  <td hidden={userRole == recepRole} md={2}>
                                    <InfoAlunosModal
                                      tipoDeAula={tipoDeAula}
                                      key={index}
                                      aula={aula}
                                      alunoData={aluno[1]}
                                      email={aluno[0]}
                                      aulaId={id}
                                    ></InfoAlunosModal>
                                  </td>
                                </tr>
                              )
                            })
                          )}
                        </tbody>
                      </Table>
                    </Row>
                   
                  </FormGroup>
                  <Label for="exampleText">Notas</Label>
                  <Input
                    type="textarea"
                    name="text"
                    value={notas}
                    id="notasID"
                    onChange={e => setNotas(e.target.value)}
                  />
                </Form>

                <Row hidden={userRole == recepRole} style={{ paddingTop: "20px", paddingBottom: "20px" }}>
                  <Col md={4}>
                    <Spinner
                      size="sm"
                      hidden={!isLoading}
                      animation="border"
                      role="status"
                    >
                      <span className="visually-hidden">Loading...</span>
                    </Spinner>
                    <Button
                      hidden={isLoading}
                      color="primary"
                      onClick={async () => {
                        if (!isAtiva && campoEscolhido.length != 0) {
                          alert("Erro \n\n Não pode adicionar um campo a uma aula inativa")
                        } else if (isAtiva && ( campoEscolhido == null ||  campoEscolhido.length == 0) ) { 
                          alert("Erro \n\nTem que escolher um campo antes de ativar uma aula")
                        }
                        else {
                        setIsLoading(true)
                        var newAula = new Aula()
                        newAula.isAtiva = isAtiva
                        newAula.estado = 1
                        newAula.nome =
                          document.getElementById("aulaInput").value
                        newAula.campos = campoEscolhido
                        newAula.diaFinal = endDate
                        newAula.diaInicial = startDate
                        newAula.horaInicial = horaInicial
                        newAula.horaFinal = horaFinal
                        newAula.localizacao =
                          document.getElementById("localSelect").value
                        newAula.professor = professor
                        newAula.weekDay =
                          document.getElementById("weekdaySelect").selectedIndex
                        newAula.nivel = nivel
                        newAula.notas = notas
                        newAula.editadaPor = {email: JSON.parse(localStorage.getItem("authUser")).email,date: firebase.firestore.Timestamp.fromDate(new Date()),edicao: {
                          "campo" : campoEscolhido,
                          "startDate" : startDate,
                          "isAtiva" : isAtiva,
                          "weekday" : document.getElementById("weekdaySelect").selectedIndex,
                          "horaInicial" : horaInicial,
                          "horaFinal" : horaFinal,
                          "alunos" : alunos
                        }};

                        const reserva = minsDiffs(
                          horaInicial,
                          horaFinal,
                          diaDaSemana
                        )
                        var procuraReserva = new ProcuraReserva()
                        procuraReserva.duracaoDaReserva = reserva[0]
                        procuraReserva.horaDaReserva = reserva[1]
                        procuraReserva.localizacao = "Great Padel Vila Verde"

                        if (tipoDeAula == "Experimental") {
                          var himinss = horaInicial.substring(3, 5)
                          var hihours = horaInicial.substring(0, 2)
                          procuraReserva.duracaoDaReserva = reserva[0]
                          procuraReserva.horaDaReserva = new Date(endDate.getFullYear(), endDate.getMonth() , endDate.getDate(), hihours, himinss);
                          procuraReserva.localizacao = "Great Padel Vila Verde"
                        }

                        const camposOcupados = await retornaCamposIndisponíveisNaHoraENDP(
                          procuraReserva
                        )

                        var aux = false
                        if(campoDaAula[0] != campoEscolhido[0] && camposOcupados['Ocupados'].includes(campoEscolhido[0]) ) {
                          alert("Este campo ja se encontra ocupado. Pesquise novamente por campos pff")
                          removeCamposRepetidos(["Campo 1","Campo 2","Campo 3","Campo 4","Campo 5","Campo 6", ])
                          setIsLoading(false)
                        } else {
                          var aux = await updateAula(newAula, id)
                          setCampoDaAula(campoEscolhido)
                        }

                        setIsLoading(false)
                        if (aux == true) {
                          alert("Aula editada com sucesso")
                          
                        } else {
                          alert(
                            "Erro a editar aula. Confirme se todos os dados estão preenchidos."
                          )
                        } 
                      }  }}
                    >
                      Editar aula
                    </Button>
                  </Col>
                </Row>
              </Container>
            </div>
          
        </React.Fragment>
      )
  }
  return rendera()
}

export default VerAulasScreen
