import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Col, Container, Input, Label,Modal, ModalBody, ModalFooter, ModalHeader, Row, Spinner } from 'reactstrap';
import { getPlanoDeNutricao, removePlanoDeNutricao, uploadPlanoDeNutricao } from 'services/nutricao/nutricao_services';
import { pesquisarJogadoresByEmail, pesquisarJogadoresByNumeroDeTele } from 'services/useres/jogadores_services';

function NutricaoScreen() {
    const [nome, setNome] = useState("")
    const [dataDeNsc, setDataDeNsc] = useState("")
    const [userData, setUserData] = useState("")
    const [email, setEmail] = useState("");
    const [tele, setTele] = useState("");
    const [isLoadingNT, setIsLoaingNT] = useState(false);
    const [isUser, setIsUser] = useState(null);
    const [docId, setDocId] = useState(null);
    const [file, setFile] = useState(null);
    const [isLoading, setIsLoading] = useState(null);
    const [isLoadingAdicioanr, setIsLoadingAdicionar] = useState(null);
    const [downlaodURL, setDonwloadURL] = useState(null);
    
    // State for Modal
    const [modal, setModal] = useState(false);

    const toggleModal = () => setModal(!modal);

    const { t, i18n } = useTranslation();

    return (  
        <React.Fragment>
             <div className="page-content">
                <Container fluid>
                    <div className="page-title-box">
                        {/* Título */}
                        <Row className="align-items-center">
                            <Col md={10}>
                                <h6 className="page-title">{t("Nutrição")}</h6>
                            </Col>
                            <Col md={2}>
                               
                            </Col>
                        </Row>
                        <Row>
                            <Row style={{ alignItems: "center", paddingBottom: "20px", paddingTop: "20px" }}>
                                <Col md={1}>
                                    <Label>Email</Label>
                                </Col>
                                <Col md={4}>
                                    <Input
                                    value={email}
                                    onChange={(e) => {
                                        setEmail(e.target.value)
                                        setIsUser(null)
                                        setDonwloadURL(null)
                                        setFile(null)
                                        setUserData("")
                                    }}
                                    id="email" 
                                    type="email" defaultValue={""}></Input>
                                </Col>
                                <Col>
                                    { isLoading ? <Spinner></Spinner> :
                                    <Button  onClick={async() => {
                                        setIsLoading(true)
                                        const doc = await pesquisarJogadoresByEmail(email)
                                        if(doc != null){
                                            const docID = doc.id
                                            setUserData(doc.data())
                                            setDocId(docID)
                                            const nutriURL = await getPlanoDeNutricao(docID)

                                        if(nutriURL) {
                                            setDonwloadURL(nutriURL)
                                            setIsUser(true);
                                            
                                            console.log(nutriURL)
                                        } else {
                                            setIsUser(false);
                                        }
                                        setIsLoading(false)
                                        } else {
                                            alert("O email não está registado")
                                        }
                                        setIsLoading(false)
                                    }} color='primary'>Pesquisar</Button>}
                                </Col>
                            </Row>
                            <Row style={{ alignItems: "center", paddingBottom: "20px", paddingTop: "20px" }}>
                                <Col md={1}>
                                    <Label>Telemóvel</Label>
                                </Col>
                                <Col md={4}>
                                    <Input
                                    value={tele}
                                    onChange={(e) => {
                                        setTele(e.target.value)
                                        setIsUser(null)
                                        setDonwloadURL(null)
                                        setFile(null)
                                        setUserData("")
                                    }}
                                    id="tele" 
                                    type="tele" defaultValue={""}></Input>
                                </Col>
                                <Col>
                                    { isLoadingNT ? <Spinner></Spinner> :
                                    <Button  onClick={async() => {
                                        setIsLoaingNT(true)
                                        const doc = await pesquisarJogadoresByNumeroDeTele(tele);
                                        
                                        if(doc[0] != null) {
                                            const docID = doc[0].id
                                        
                                        setUserData(doc[0].data())
                                        setDocId(docID)
                                        const nutriURL = await getPlanoDeNutricao(docID)

                                        if(nutriURL) {
                                            setDonwloadURL(nutriURL)
                                            setIsUser(true);
                                            
                                            console.log(nutriURL)
                                        } else {
                                            setIsUser(false);
                                        }
                                        setIsLoaingNT(false)
                                        } else {
                                            alert("O email não está registado")
                                        }
                                        setIsLoaingNT(false)
                                    }} color='primary'>Pesquisar</Button>}
                                </Col>
                            </Row>
                            
                            
                                <Row hidden={ !(userData != null && userData != "")}>
                               
                                <Col style={{paddingTop: "20px"}}   md={12}>
                                    <h4>Informações do Utilizador</h4>
                                </Col>
                                <Col md={3}>
                                    <Label>Data de Nascimento:</Label>
                                    <p>
                                    {userData.dataDeNascimento
                                        ? new Date(userData.dataDeNascimento.seconds * 1000).toLocaleDateString()
                                        : "N/A"}
                                </p>
                                </Col>
                                <Col md={3}>
                                    <Label>Nome:</Label>
                                    <p>{userData.primeiroNome + " " + userData.ultimoNome || "N/A"}</p>
                                </Col>
                                
                                <Col md={3}>
                                    <Label>Email</Label>
                                    <p>{userData.email || "N/A"}</p>
                                </Col>
                            
                                <Col md={3}>
                                    <Label>Telemóvel:</Label>
                                    <p>{userData.numeroTele || "N/A"}</p>
                                </Col>

                                <Col hidden={!(isUser==true)}  >
                                
                                 <Row>
                                   
                                    <Col md={8}>
                                    <object data={downlaodURL} type="application/pdf" width="100%" height="550px">
                                        
                                    </object>
                                    </Col>

                                    <Row >
                                    <Col>
                                                <Button 
                                                    style={{ marginTop: "20px" }} 
                                                    color='danger'
                                                    onClick={toggleModal}
                                                >
                                                    Remover
                                                </Button>

                                                {/* Modal Component */}
                                                <Modal isOpen={modal} toggle={toggleModal}>
                                                    <ModalHeader toggle={toggleModal}>
                                                        Confirmação de Remoção
                                                    </ModalHeader>
                                                    <ModalBody>
                                                        Tem certeza de que deseja remover este ficheiro?
                                                    </ModalBody>
                                                    <ModalFooter>
                                                        <Button color="danger" onClick={async () => {
                                                            // Action to remove file
                                                            const res = await removePlanoDeNutricao(docId)
                                                            if(res) {
                                                                setIsUser(null)
                                                                setDonwloadURL(null)
                                                                setFile(null)
                                                                setUserData("")
                                                                alert("Ficheiro removido com sucesso")
                                                            } else {
                                                                alert("Erro a remover ficheiro")
                                                            }
                                                            toggleModal();
                                                        }}>
                                                            Remover
                                                        </Button>{' '}
                                                        <Button color="secondary" onClick={toggleModal}>
                                                            Cancelar
                                                        </Button>
                                                    </ModalFooter>
                                                </Modal>
                                            </Col>
                                    </Row>
                                    <h3 style={{paddingTop: "40px"}} >Adicionar novo ficheiro</h3>
                                    </Row>
                                    <Row style={{paddingTop: "40px", paddingBottom: "40px"}}>
                                    <Input onChange={(e) => {
                                        setFile(e.target.files[0])
                                    }} type='file' accept="application/pdf"></Input>
                                    </Row>
                                    <Row>
                                        <Col  md={6}>
                                    { isLoadingAdicioanr ? <Spinner></Spinner> :
                                    <Button onClick={async () => {
                                        setIsLoadingAdicionar(true)
                                        console.log("Ficheiro:" , file);
                                        const res = await uploadPlanoDeNutricao(docId,file)
                                       if(res) {
                                        alert("Ficheiro adicionado com sucesso")
                                        const nutriURL = await getPlanoDeNutricao(docId)

                                        if(nutriURL) {
                                            setDonwloadURL(nutriURL)
                                            setIsUser(true);
                                            
                                            console.log(nutriURL)
                                        } else {
                                            setIsUser(false);
                                        }
                                       } else {
                                        alert("Erro")
                                       }
                                       setIsLoadingAdicionar(false)
                                    }} color='primary'>Adicionar</Button>}
                                    </Col>
                                    </Row>
                                </Col> 
                                </Row>
                                <Row>
                                <Col  hidden={!(isUser==false)}  >
                                    <Row>
                                    <h3 style={{paddingTop: "40px"}} > Sem ficheiro de nutrição</h3>
                                    </Row>
                                    <Row style={{paddingTop: "40px", paddingBottom: "40px"}}>
                                    <Input onChange={(e) => {
                                        setFile(e.target.files[0])
                                        
                                    }} type='file' accept="application/pdf"></Input>
                                    </Row>
                                    <Row>
                                        <Col  md={6}>
                                    { isLoadingAdicioanr ? <Spinner></Spinner> :
                                    <Button onClick={async () => {
                                        setIsLoadingAdicionar(true)
                                        console.log("Ficheiro:" , + file);
                                        const res = await uploadPlanoDeNutricao(docId,file)
                                       
                                       if(res) {
                                        alert("Ficheiro adicionado com sucesso")
                                        const nutriURL = await getPlanoDeNutricao(docId)

                                        if(nutriURL) {
                                            setDonwloadURL(nutriURL)
                                            setIsUser(true);
                                            setFile(nutriURL)
                                            console.log(nutriURL)
                                        } else {
                                            setIsUser(false);
                                        }
                                       } else {
                                        alert("Erro")
                                       }
                                       setIsLoadingAdicionar(false)
                                    }} color='primary'>Adicionar</Button>}
                                    </Col>
                                    </Row>
                                </Col>
                                </Row>
                            </Row>
                        
                    </div>
                </Container>
            </div>
        </React.Fragment>
    );
}

export default NutricaoScreen;