import { ProcuraReserva } from 'models/reserva';
import React, { useEffect, useState } from 'react';
import { Pencil } from 'react-bootstrap-icons';
import DatePicker from 'react-date-picker';
import DateTimePicker from 'react-datetime-picker/dist/DateTimePicker';
import { useSelector } from 'react-redux';
import TimePicker from 'react-time-picker/dist/TimePicker';
import { Button, Col, Form, FormGroup, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader, Row, Spinner } from 'reactstrap';
import { convertCamps } from 'services/consts';
import { bloquearComReservas, bloquearProximoFimDeSemana, bloquearReservasComCriacaoDeReservas, deleteBloqueio, removeBloqueioAnterio, retornaCamposIndisponíveisNaHora, retornaCamposIndisponíveisNaHoraEditarBloqueioDeHoras, retornaCamposIndisponíveisNaHoraEditarENDP } from 'services/reservas/reservas_services';
import { selectbloquearReservas } from 'store/bloquear_reservas/bloquear_reservas_reducer';
import { selectclubeid } from 'store/localizacao/clube_id_reducer';
import { selectlocalizacao } from 'store/localizacao/localizacao_reducer';

function EditarBloqueioHorario(props) {
    const [isOpen, setIsOpen] = useState(false)
    const [name, setName] = useState(props.name)
    const [horaInicialAtinga, setHoraInicalAntiga] = useState(null)
    const [horaFinalAtinga, setHoraFinalAntiga] = useState(null)
    const [camposDisponivAntigos, setCamposDisponivAntigos] = useState([])

    const [isCamposIndisLoading, setIsCamposIndisLoading] = useState(false)

    const localizacao = useSelector(selectlocalizacao)
    const [horaInicial, setHoraInicial] = useState("00:00")
    const [horaFinal, setHoraFinal] = useState("00:00")
    const [startDate, setStartDate] = useState( new Date())
    const [endDate, setEndDate] = useState(  new Date())
    const [camposDisponiv, setCamposDisponiv] = useState([])
    const [isValidDate, setIsValidDate] = useState(true)
    const numeroDeCampos = 6

    const [dataValue, setDataValue] = useState(new Date())
    const minDate = new Date()

    const [isLoading, setIsLoading] = useState(false);

    const horariosBloqueadosList = useSelector(selectbloquearReservas)
    /* const hbJson = JSON.parse( horariosBloqueadosList) */
    const docId = useSelector(selectclubeid);

    const listaDeBlocks = useSelector(selectbloquearReservas)
    var listB = []
    for(const elem in listaDeBlocks) {
        listB.push(elem)
    } 
    /* const listaDeBlocksJson = JSON.parse(listaDeBlocks) */
    const clubeId = useSelector(selectclubeid)

  function toggle() {
    setIsOpen(!isOpen)
  }

  

  function removeCamposRepetidos(campoosInsdis) {
    console.log("A localizacao dos campos é :")
    console.log(localizacao)
    var listaDeCampos = []
    var novaLista = []
    for (var i = 1; i <= numeroDeCampos; i++) {
      var stringAux = "Campo " + i
      if (campoosInsdis.includes(stringAux) == false) {
        listaDeCampos.push(stringAux)
      }
    }
    setCamposDisponiv(listaDeCampos/*  .concat(["Campo 1", "Campo 2"]) */  )
  }

  const [camposDisp, setCamposDisp] = useState([
    "Campo 1",
    "Campo 2",
    "Campo 3",
    "Campo 4",
    "Campo 5",
    "Campo 6",
  ])

  const [campoEscolhido, setCampoEscolhido] = useState([

  ])
  const removeCamposEsc = value => {
    setCampoEscolhido(campoEscolhido.filter(item => item !== value))
  }

  function restDados() {
    setIsLoading(false)
    setName(null)
    setStartDate(new Date())
    setEndDate(new Date())
    setCamposDisp([])
    setCamposDisponiv([])
    setCampoEscolhido([])
  }

  function diffInMinutes(date1, date2) {
    var diff = date2.valueOf() - date1.valueOf()
    var diffInMins = diff / 1000 / 60 
    console.log(diffInMins.toFixed(1))
    return diffInMins.toFixed(1)
  }

  function diffInHours(date1, date2) {
    var diff = date2.valueOf() - date1.valueOf()
    var diffInHours = diff / 1000 / 60 / 60
  
    return diffInHours.toFixed(1)
  }

  function setDados() {
    /* setHoraInicalAntiga(props.vals.horaFinal.toDate())
    setHoraFinalAntiga(props.vals.horaFinal.toDate())
    setHoraFinalAntiga(props.vals.campos)  */
    setCamposDisponiv(props.vals.tipoDeBloqueio == "Desativar" ? 
      ["Campo 1",
      "Campo 2",
      "Campo 3",
      "Campo 4",
      "Campo 5",
      "Campo 6",] : 
    props.vals.campos)
    
    setCampoEscolhido(props.vals.campos)
    setName(props.name);
    var userTimezoneOffset = startDate.getTimezoneOffset() * 60000;
    var initlDate = new Date(props.vals.horaInicial.toDate().getTime() + userTimezoneOffset);
    var finalDate = new Date(props.vals.horaFinal.toDate().getTime() + userTimezoneOffset);

    setEndDate(finalDate),
    setStartDate(initlDate)
  }

  /* useEffect(()=> {
    setEndDate(props.vals.horaFinal.toDate()),
    setStartDate(props.vals.horaInicial.toDate())
  }, []) */
  useEffect(() => {
    setDados()
  }, [])

    return (  
        <React.Fragment>
            <Button style={{ marginRight: "10px"}} color='btn btn-warning' onClick={() => {
                setDados()
              toggle()}}>
                <Pencil></Pencil>
            </Button>
            <Modal isOpen={isOpen}
        toggle={() => {
          /* restDados()  */
          setIsOpen(!isOpen)
        }}
      >
        <ModalHeader
          toggle={() => {
            /* restDados()  */
            setIsOpen(!isOpen)
          }}
        >
          Editar Bloqueio Horário
        </ModalHeader>
        <ModalBody>
          <Form>
          <FormGroup row>
              <Label for="nome" md={3}>
                <p>Nome</p>
              </Label>
              <Col md={9}>
                <Input
                disabled={true}
                value={name}
                  type="text"
                  name="nomeInput"
                  id="nomeInput"
                  placeholder="nome"
                />
              </Col>
            </FormGroup>
          
            <FormGroup>
                <Row>
                    <Col md={3}>
                    <p>Horário Inicial</p>
                    </Col>
                    <Col md={9}>
                        <DateTimePicker
                        
                            disableClock={true}
                            value={startDate} 
                            minDate={minDate}
                            onChange={(value) => {
                              setCamposDisponiv([])
                              setCampoEscolhido([])
                                console.log(value);
                                setStartDate(value)
                            }}>
                        </DateTimePicker>
                    </Col>
                </Row>
            
            </FormGroup>
            <FormGroup>
                <Row>
                    <Col md={3}>
                    <p>Horário Final</p>
                    </Col>
                    <Col md={9}>
                    <DateTimePicker
                    
                    disableClock={true}
                        value={endDate} 
                        minDate={minDate}  
                        onChange={(value) => {
                          setCampoEscolhido([])
                          setCamposDisponiv([])
                            console.log(value);
                            setEndDate(value)
                        }}>
                    </DateTimePicker>
                    </Col>
                </Row>
            
            </FormGroup>
            <FormGroup style={{ paddingTop: "20px" }} hidden={props.vals.tipoDeBloqueio == "Desativar"}>
            {isCamposIndisLoading ? <Spinner color="primary"></Spinner> :
            <Button
              color="primary"
              onClick={async () => {
                setIsCamposIndisLoading(true)
                var copiedDate = startDate
                var procuraReserva = new ProcuraReserva()
                
                procuraReserva.duracaoDaReserva =
                diffInMinutes(startDate,endDate);
                procuraReserva.horaDaReserva = copiedDate
                procuraReserva.localizacao = localizacao
                console.log("BP0")
                  await retornaCamposIndisponíveisNaHoraEditarENDP(
                    procuraReserva,
                    name
                  ).then((value) => {
                    console.log(value)
                    removeCamposRepetidos(value['Ocupados'])
                  })
                /* removeCamposRepetidos(camposs) */
                setIsCamposIndisLoading(false)
              }}
              disabled={!isValidDate}
            >
              {" "}
              Mostrar Campos disponiveis
            </Button>}
            </FormGroup>
            <FormGroup>
            <h5 style={{paddingTop: "20px", paddingBottom: "10px"}}> Campos a bloquear </h5>
            <Row>
                {camposDisponiv.map((value, index) => {
                  return (
                    <Col key={index} md={4} style={{ paddingBottom: "10px" }}>
                      <Button
                        outline={campoEscolhido.includes(value) ? false : true}
                        color="primary"
                        onClick={() => {
                          if (!campoEscolhido.includes(value)) {
                            setCampoEscolhido(campoEscolhido => [
                              ...campoEscolhido,
                              value,
                            ])
                          } else {
                            removeCamposEsc(value)
                          }
                        }}
                      >
                        {convertCamps[value]}
                      </Button>
                    </Col>
                  )
                })}
              </Row>
            </FormGroup>
          </Form>
        </ModalBody>
        <ModalFooter>
          {/*   <Button onClick={async () => {
            await bloquearProximoFimDeSemana("Great Padel Vila Verde"
            , "Ca bloqueio total", campoEscolhido )
          }}>
            Bloquear fds
          </Button>   */}
           {isLoading ? <Spinner color='primary' ></Spinner> :
            <Button color='primary' onClick={async () => {
              setIsLoading(true)
                var listaAux = []
                var listaAux2 = []

                var res1 = await removeBloqueioAnterio(docId ,horariosBloqueadosList[name].horaInicial.toDate(),horariosBloqueadosList[name].horaFinal.toDate(),localizacao,name );

                var userTimezoneOffset = startDate.getTimezoneOffset() * 60000;
                var newInitialDate = new Date(startDate.getTime() - userTimezoneOffset);
                var newFinalDate = new Date(endDate.getTime() - userTimezoneOffset);
                var res = await bloquearComReservas(newInitialDate,newFinalDate,localizacao,
                clubeId,{}, name, campoEscolhido, props.vals.tipoDeBloqueio  )

                if(res1 && res) {
                  alert("Dados alterados com sucesso")
                  setIsLoading(false)
                } else {
                  alert("Erro")
                  setIsLoading(false)
                }
                setIsLoading(false)
               
            }} >Editar campos</Button>}
            
            <Button onClick={() => {toggle()}} >Cancelar </Button>

        </ModalFooter>
       
            </Modal>
        </React.Fragment>
    );
}

export default EditarBloqueioHorario;