import React, { useEffect, useState } from "react"
import DateTimePicker from "react-datetime-picker"
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Form,
  FormGroup,
  Label,
  Row,
  Col,
  Input,
  Spinner,
  Alert,
} from "reactstrap"
import TimePicker from "react-time-picker"
import DatePicker from "react-date-picker"
import { element } from "prop-types"
import { Aula } from "models/aula"
import { createAula } from "services/aulas/aulas_services"
import { PlusCircle } from "react-bootstrap-icons"
import { useSelector } from "react-redux"
import { selecttreinadores } from "store/treinadores/treinadores_reducer"
import { convertCamps, niveis } from "services/consts"
import { ProcuraReserva } from "models/reserva"
import { retornaCamposIndisponíveisNaHora, retornaCamposIndisponíveisNaHoraENDP } from "services/reservas/reservas_services"

function AdicionarAulasModal(props) {
  var asd = new Date()
  var treinadores = [" "].concat(useSelector(selecttreinadores))
  const [isOpen, setIsOpen] = useState(false)
  const [horaInicial, setHoraInicial] = useState("00:00")
  const [horaFinal, setHoraFinal] = useState("00:00")
  const [startDate, setStartDate] = useState(asd)
  const [endDate, setEndDate] = useState(asd)
  const [campoEscolhido, setCampoEscolhido] = useState([])
  const [nivel, setNivel] = useState(1)
  const [isLoading, setIsLoading] = useState(false)
  const [experimentData, setExperimentalData] = useState(new Date())

  const [isExperimental, setIsEepxre] = useState(true)
  const [tipoDeAula, setTipoDeAula] = useState("")

  const [isAtiva, setIsAtiva] = useState(true)
  const [isCamposIndisLoading, setIsCamposIndisLoading] = useState(false)

  const handleChange = event => {
    if (event.target.checked) {
      console.log("✅ Checkbox is checked")
    } else {
      console.log("⛔️ Checkbox is NOT checked")
    }
    setIsAtiva(current => !current)
  }

  const [diaDaSemana, setDiaDaSemana] = useState(0)

  const [camposDisp, setCamposDisp] = useState([])
  const numeroDeCampos = 6

  var weekDay = new Array(8)
  weekDay[0] = " "
  weekDay[1] = "Segunda"
  weekDay[2] = "Terça"
  weekDay[3] = "Quarta"
  weekDay[4] = "Quinta"
  weekDay[5] = "Sexta"
  weekDay[6] = "Sábado"
  weekDay[7] = "Domingo"

  let alphabet =[""].concat( [...Array(24)].map((_, i) => String.fromCharCode(i + 65)));
  const [aplhabetoAA, setAplhabetoAA] = useState([])

  const removeCamposEsc = value => {
    setCampoEscolhido(campoEscolhido.filter(item => item !== value))
  }

  function minsDiffs(horaInicial, horaFinal, weekDay) {
    var hi = new Date()
    var hf = new Date()
    var himins = horaInicial.substring(3, 5)
    var hihour = horaInicial.substring(0, 2)
    var hfmins = horaFinal.substring(3, 5)
    var hfhour = horaFinal.substring(0, 2)
    hi.setHours(hihour)
    hi.setMinutes(himins)
    hf.setHours(hfhour)
    hf.setMinutes(hfmins)

    var diffMs = hf - hi
    var duracao = Math.round(diffMs / 60000) // minutes
    console.log(duracao + " minutes ")

    var today = hi.getDay()
    if (today == 0) {
      today = 7
    }

    if (today > weekDay) {
      weekDay = weekDay + 7
    }

    var daysDiff = weekDay - today

    console.log(hi.getDay())
    console.log(weekDay)
    console.log(daysDiff)

    var dia = hi.getDate()
    var mes = hi.getMonth()
    var ano = hi.getFullYear()
    var checkDate = new Date(ano, mes, dia + daysDiff, hihour, himins)
    console.log(checkDate)

    return [duracao, checkDate]
  }

  function removeCamposRepetidos(campoosInsdis) {
    var listaDeCampos = []
    var novaLista = []
    for (var i = 1; i <= numeroDeCampos; i++) {
      var stringAux = "Campo " + i
      if (campoosInsdis.includes(stringAux) == false) {
        listaDeCampos.push(stringAux)
      }
    }
    setCamposDisp(listaDeCampos)
  }

  function nomedaTurmafunc() {
    var listaAuxxx= []
    for (let index = 0; index < alphabet.length; index++) {
      for (let j = 0; j < alphabet.length; j++) {
        var pafi = alphabet[index] + alphabet[j]
        listaAuxxx.push(pafi);
      }
    }
    console.log(props.listaDeNomes)
    const resultArray = listaAuxxx.filter(item => !props.listaDeNomes.includes(item));
    console.log(resultArray)
    setAplhabetoAA(resultArray)
  }

  function limpaDados() {

    setHoraInicial("00:00")
    setHoraFinal("00:00")
    setStartDate(asd)
    setEndDate(asd)
    setCampoEscolhido([])
    setExperimentalData(new Date()) 
    removeCamposRepetidos(["Campo 1","Campo 2","Campo 3","Campo 4","Campo 5","Campo 6", ])

  }

  useEffect(() => {
    nomedaTurmafunc();
  }, [])

  return (
    <div>
      <Button
        color="primary"
        onClick={() => {
          limpaDados()
          setIsOpen(!isOpen)
        }}
      >
        Adicionar Aula
      </Button>
      <Modal
        isOpen={isOpen}
        toggle={() => {
          setIsOpen(!isOpen)
        }}
      >
        <ModalHeader
          toggle={() => {
            setIsOpen(!isOpen)
          }}
        >
          Adicionar Aula
        </ModalHeader>
        <ModalBody>
          
          <Form>
            <FormGroup check>
              <Label check>
                <Input
                  type="checkbox"
                  checked={isAtiva}
                  value={isAtiva}
                  onChange={handleChange}
                  id="isAulaAtiva"
                  name="subscribe"
                />{" "}
                Ativa
              </Label>
            </FormGroup>
            <FormGroup>
              <Row>
                <Col md={3}>
                  <p> Tipo </p>
                </Col>
                <Col md={9}>
                  <Input
                  value={tipoDeAula}
                    onChange={e => {
                      nomedaTurmafunc()
                      setTipoDeAula(e.target.value)
                      setIsEepxre(document.getElementById("tipoAula").value)
                    }}
                    type="select"
                    name="select"
                    id="tipoAula"
                  >
                    <option></option>
                    <option>Experimental</option>
                    <option>Academia</option>
                    <option>PAX</option>
                  </Input>
                </Col>
              </Row>
            </FormGroup>
            <FormGroup hidden={tipoDeAula == "Academia" ? false : true}>
              <Row>
                <Col md={3}>
                  <p>Nome da Aula</p>
                </Col>
                <Col md={9}>
                  <Input
                    type="select"
                    name="aulaInput"
                    id="aulaInput"
                    placeholder="Nome da aula"
                  >{aplhabetoAA.map((element, index) => {
                    return <option key={index}>{element}</option>
                  })}</Input>
                </Col>
              </Row>
            </FormGroup>
            <FormGroup hidden={tipoDeAula == "Experimental" ? false : true}>
              <Row>
                <Col md={3}>
                  <p>Nome da Aula</p>
                </Col>
                <Col md={9}>
                    Y
                </Col>
              </Row>
            </FormGroup>
            <FormGroup hidden={tipoDeAula == "PAX" ? false : true}>
              <Row>
                <Col md={3}>
                  <p>Nome da Aula</p>
                </Col>
                <Col md={9}>
                    Z
                </Col>
              </Row>
            </FormGroup>

           
            <FormGroup hidden={tipoDeAula == "Experimental" ? false : true} >
              <Row>
                <Col md={3}>
                  <p>Dia</p>
                </Col>
                <Col md={9}>
                  <DatePicker
                    onChange={(e) => {
                      setCampoEscolhido([])
                      removeCamposRepetidos(["Campo 1","Campo 2","Campo 3","Campo 4","Campo 5","Campo 6", ])
                      setExperimentalData(e)
                    
                    }}
                    value={experimentData}
                    disableClock={true}
                  ></DatePicker>
                </Col>
              </Row>
            </FormGroup>
            <FormGroup>
              <Row>
                <Col md={3}>
                  <p>Hora Inicial</p>
                </Col>
                <Col md={9}>
                  <TimePicker
                  disabled={isCamposIndisLoading}
                    onChange={(e) => {
                      setCampoEscolhido([])
                      removeCamposRepetidos(["Campo 1","Campo 2","Campo 3","Campo 4","Campo 5","Campo 6", ])
                      setHoraInicial(e)
                    }}
                    value={horaInicial}
                    disableClock={true}
                  ></TimePicker>
                </Col>
              </Row>
            </FormGroup>
            <Row>
              <Col md={3}>
                <p>Hora Final</p>
              </Col>
              <Col md={9}>
                <TimePicker
                disabled={isCamposIndisLoading}
                 onChange={(e) => {
                  setCampoEscolhido([])
                  removeCamposRepetidos(["Campo 1","Campo 2","Campo 3","Campo 4","Campo 5","Campo 6", ])
                  setHoraFinal(e)
                }}
                  value={horaFinal}
                  disableClock={true}
                ></TimePicker>
              </Col>
            </Row>
            <FormGroup hidden={tipoDeAula == "Experimental" ? true : false}>
              <Row>
                <Col md={3}>
                  <p>Dia da semana</p>
                </Col>
                <Col md={9}>
                  <Input
                    disabled={isCamposIndisLoading}
                    value={weekDay[diaDaSemana]}
                    onChange={e => {
                      removeCamposRepetidos(["Campo 1","Campo 2","Campo 3","Campo 4","Campo 5","Campo 6", ])
                      setDiaDaSemana(
                        document.getElementById("weekDaySelect").selectedIndex
                      )
                      console.log(
                        document.getElementById("weekDaySelect").selectedIndex
                      )
                    }}
                    type="select"
                    name="select"
                    id="weekDaySelect"
                  >
                    {weekDay.map((element, index) => {
                      return <option key={index}>{element}</option>
                    })}
                  </Input>
                </Col>
              </Row>
            </FormGroup>
            <Row>
              <Col md={3}>
                <p>Localização</p>
              </Col>
              <Col md={9}>
                <FormGroup>
                  <Input type="select" name="select" id="localSelect">
                    <option>Great Padel Vila Verde</option>
                  </Input>
                </FormGroup>
              </Col>
            </Row>
            <FormGroup>
              <Row>
                <Col md={3}>
                  <p>Professor</p>
                </Col>
                <Col md={9}>
                  <Input
                    type="select"
                    name="select"
                    onChange={e => {
                      document.getElementById("professorInput2").value =
                        e.target.value
                    }}
                    id="professorInput2"
                  >
                    {treinadores.map((elem, index) => {
                      return <option key={index}>{elem.nome}</option>
                    })}
                  </Input>
                </Col>
              </Row>
            </FormGroup>

            <FormGroup>
              <Row>
                <Col md={3}>
                  <p>Nivel</p>
                </Col>
                <Col md={9}>
                  <Input
                    value={nivel}
                    onChange={e => {
                      setNivel(e.target.value)
                    }}
                    type="select"
                    name="select"
                    id="nivelSelect"
                  >
                    {niveis.map((elem, index) => {
                      return <option key={index}>{elem}</option>
                    })}
                  </Input>
                </Col>
              </Row>
            </FormGroup>
            <FormGroup>
              <p style={{ paddingTop: "20px", fontWeight: "bold" }}>Campos</p>
                  
              {isCamposIndisLoading ? <Spinner color="primary"></Spinner> :
              <Button
                style={{ marginBottom: "20px" }}
                color="primary"
                onClick={async () => {
                  console.log("Patig tabay")
                  setIsCamposIndisLoading(true)
                  console.log(experimentData.getDay())
                  console.log(experimentData)
                   if (horaInicial == "00:00") {
                    alert("É necessário escolher a hora inicial ")
                  }
                  if (horaFinal == "00:00") {
                    alert("É necessário escolher a hora final ")
                  } else if (diaDaSemana == 0 && tipoDeAula != "Experimental") {
                    alert("É necessário escolher o dia da semana ")
                  } else {

                    var reserva = minsDiffs(
                      horaInicial,
                      horaFinal,
                      diaDaSemana
                    ) 
                    if (tipoDeAula == "Experimental") {
                      reserva = minsDiffs(
                        horaInicial,
                        horaFinal,
                        experimentData.getDay() 
                      ) 
                    }
                    var procuraReserva = new ProcuraReserva()
                    procuraReserva.duracaoDaReserva = reserva[0]
                    procuraReserva.horaDaReserva = reserva[1]
                    procuraReserva.localizacao = "Great Padel Vila Verde"
                    
                    if (tipoDeAula == "Experimental") {
                      var himinss = horaInicial.substring(3, 5)
                      var hihours = horaInicial.substring(0, 2)
                      procuraReserva.duracaoDaReserva = reserva[0]
                      procuraReserva.horaDaReserva = new Date(experimentData.getFullYear(), experimentData.getMonth() , experimentData.getDate(), hihours, himinss);
                      procuraReserva.localizacao = "Great Padel Vila Verde"
                    }

                    await retornaCamposIndisponíveisNaHoraENDP(
                      procuraReserva
                    ).then((value) => {
                      console.log(value)
                      removeCamposRepetidos(value['Ocupados'])  
                    })
                    /* var camposs = await retornaCamposIndisponíveisNaHora(
                      procuraReserva
                    )
                    removeCamposRepetidos(camposs) */
                  } 
                  setIsCamposIndisLoading(false)
                }}
              >
                Mostrar campos disponíveis
              </Button>}
              <Row>
                {camposDisp.map((value, index) => {
                  return (
                    <Col key={index} md={4} style={{ paddingBottom: "10px" }}>
                      <Button
                        outline={campoEscolhido.includes(value) ? false : true}
                        color="primary"
                        onClick={() => {
                          if (!campoEscolhido.includes(value)) {
                            setCampoEscolhido([
                              value
                            ])
                          } else {
                            removeCamposEsc(value)
                          }
                        }}
                      >
                        {convertCamps[value]}
                      </Button>
                    </Col>
                  )
                })}
              </Row>
            </FormGroup>
          </Form>
        </ModalBody>
        <ModalFooter>
          <Spinner
            size="sm"
            hidden={!isLoading}
            animation="border"
            role="status"
          >
            <span className="visually-hidden">Loading...</span>
          </Spinner>
          <Button
            hidden={isLoading}
            color="primary"
            onClick={async () => {
              if (horaInicial == "00:00") {
                alert("É necessário escolher a hora inicial ")
              }
              else if (horaFinal == "00:00") {
                alert("É necessário escolher a hora final ")
              } else if (diaDaSemana == 0 && tipoDeAula != "Experimental") {
                alert("É necessário escolher o dia da semana ")
              }
              else if ( tipoDeAula == "") {
                alert("É necessário escolher o tipo da Aula")
              } else {
              setIsLoading(true)
              var newAula = new Aula()
              newAula.isAtiva = isAtiva
              newAula.estado = 1
              newAula.nome = tipoDeAula =="Experimental" ? "Y" : tipoDeAula =="PAX" ? "Z" :document.getElementById("aulaInput").value
              newAula.alunos = []
              newAula.alunosPAX = []
              newAula.alunosData = {}
              newAula.tipo = tipoDeAula
              newAula.campos = campoEscolhido
              newAula.diaFinal = experimentData
              newAula.diaInicial = startDate
              newAula.horaInicial = horaInicial
              newAula.horaFinal = horaFinal
              newAula.localizacao = document.getElementById("localSelect").value
              newAula.professor =
                document.getElementById("professorInput2").value
              newAula.weekDay = tipoDeAula == "Experimental" ? experimentData.getDay()  : 
                document.getElementById("weekDaySelect").selectedIndex
              newAula.nivel = nivel
              newAula.expiryAt = null
              newAula.notas = ""
              newAula.criadaPor = JSON.parse(localStorage.getItem("authUser")).email
              newAula.editadaPor = [{
                "campo": campoEscolhido,
                "isAtiva": isAtiva,
                "horaInicial": horaInicial,
                "horaFinal": horaFinal,
                "weekDay" : newAula.weekDay,
                "startDate": startDate,
              }]
              
              var reserva = minsDiffs(
                horaInicial,
                horaFinal,
                diaDaSemana
              ) 
              if (tipoDeAula == "Experimental") {
                reserva = minsDiffs(
                  horaInicial,
                  horaFinal,
                  experimentData.getDay() 
                ) 
              }
              var procuraReserva = new ProcuraReserva()
              procuraReserva.duracaoDaReserva = reserva[0]
              procuraReserva.horaDaReserva = reserva[1]
              procuraReserva.localizacao = "Great Padel Vila Verde"

              if (tipoDeAula == "Experimental") {
                var himinss = horaInicial.substring(3, 5)
                var hihours = horaInicial.substring(0, 2)
                procuraReserva.duracaoDaReserva = reserva[0]
                procuraReserva.horaDaReserva = new Date(experimentData.getFullYear(), experimentData.getMonth() , experimentData.getDate(), hihours, himinss);
                procuraReserva.localizacao = "Great Padel Vila Verde"
              }
  
              const camposOcupados = await retornaCamposIndisponíveisNaHoraENDP(
                procuraReserva
              );
              var aux = false
              console.log(camposOcupados['Ocupados'])
              console.log(campoEscolhido)
              if( camposOcupados['Ocupados'].includes(campoEscolhido[0]) ) {
                alert("Este campo ja se encontra ocupado. Pesquise novamente por campos")
                removeCamposRepetidos(["Campo 1","Campo 2","Campo 3","Campo 4","Campo 5","Campo 6", ])
                setIsLoading(false)
              } else {
                aux = await createAula(newAula)
              }
              /*  setIsOpen(!isOpen) */
              setIsLoading(false)
              if (aux == true) {
                alert("Aula criada com sucesso")
                setIsOpen(!isOpen)
              } else {
                alert(
                  "Erro a criar aula. Confirme se todos os dados estão preenchidos. Se sim, por favor tente mais tarde"
                )
              } }
            }}
          >
            Adicionar Aula
          </Button>
         
          <Button
            color="secondary"
            onClick={() => {
              setIsOpen(!isOpen)
            }}
          >
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  )
}

export default AdicionarAulasModal
