import React, { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { Button, Col, Container, Input, Row, Table } from "reactstrap"
import { removeAula, removeAulasExperimentais, testeDOEmailDasExpire, verListaDeAulas } from "services/aulas/aulas_services"
import firebase from "firebase/app"
import "firebase/firestore"
import { aulasCollection, convertCamps, diasDaSemana, niveis } from "services/consts"
import AdicionarAulasModal from "components/modals/adicionar_aulas_modal"
import { ArrowRightCircle, Trash2 } from "react-bootstrap-icons"
import { Link } from "react-router-dom"
import RemoveModal from "components/modals/remove_modal"
import EditarAulasModal from "components/modals/editar_aula_modal"
import { element } from "prop-types"
import { useSelector } from "react-redux"
import { selecttreinadores } from "store/treinadores/treinadores_reducer"
import VerAulasScreen from "./ver_aulas_screen"

function AulasScreen() {
  const { t, i18n } = useTranslation()
  const [listaDeAulas, setListaDeAulas] = useState([])
  const professores = [" "].concat(useSelector(selecttreinadores))
  
  const [diaDaSemana, setDiaDaSemana] = useState(0)
  const [trienador, setTreinador] = useState(professores[0].nome)
  const [aluno, setAluno] = useState("")
  const [tipo, setTipo] = useState("")
  const [nome, setNome] = useState("")
  const [nivel, setNivel] = useState("")
  const [listaDeNomes, setlistaDeNomes] = useState([]);

  const [filtroProfessor, setFiltroProfessor] = useState([])
  const [filtroCampo, setfiltroCampo] = useState([])
  const [filtroDiaDaSemana, setfiltroDiaDaSemana] = useState([])

  let alphabeto =[""].concat( [...Array(24)].map((_, i) => String.fromCharCode(i + 65)));
  const [aplhabetoAA, setAplhabetoAA] = useState([])



  const [listaFiltrada, setListaFiltrada] = useState([])

  var weekday = new Array(8)
  weekday[0] = " "
  weekday[1] = "Segunda"
  weekday[2] = "Terça"
  weekday[3] = "Quarta"
  weekday[4] = "Quinta"
  weekday[5] = "Sexta"
  weekday[6] = "Sábado"
  weekday[7] = "Domingo"

  async function verListaDeAulas(localizacao) {
    try {
      return firebase
        .firestore()
        .collection(aulasCollection)
        .where("localizacao", "==", localizacao).where("tipo", "!=", "Contrato")
        .onSnapshot(docsSnap => {
          var listaDeAulasAux = []
          var listaDeNomesAux = [];
          for (const element of docsSnap.docs) {
            listaDeNomesAux.push(element.data().nome);
            /* if(element.data().professor !="Contratos") { */
            listaDeAulasAux.push(element)
          /* } */
          }
          listaDeAulasAux.sort(function (a,b) {  
          if(a.data().weekDay < b.data().weekDay) {
            return -1
          } if(a.data().weekDay > b.data().weekDay) {
            return 1
          } if(a.data().weekDay == b.data().weekDay) {
            if(a.data().horaInicial < b.data().horaInicial) {
              return -1 
            } else {
              return 1
            }
          }
        
        })
          console.log("Lista de nomes: " , listaDeNomesAux);
          setlistaDeNomes(listaDeNomesAux);
          setListaDeAulas(listaDeAulasAux)
          setListaFiltrada(listaDeAulasAux)
          rendera()
        })
       
    } catch (e) {
      console.error(e)
      return null
    }
  }

  function filtraPorProfessor(professor,diaDaSemana,aluno,tipo,nivel) {
    setListaFiltrada([])
    var listaAux = [].concat([...listaDeAulas])
    console.log(professor)
    var listaAUx2 = []
    setListaFiltrada([])
    console.log("listaFiltrada")
    console.log(listaFiltrada)

    for (let index = 0; index < listaAux.length; index++) {
      if (listaAux[index].data().professor == professor) {
        console.log(listaAux[index].data().professor)
        listaAUx2.push(listaAux[index])
      }
    }
    setListaFiltrada([])
    setListaFiltrada(listaAUx2)
  }

  function filtraPorCampo(professor,diaDaSemana,aluno,tipo,nivel,nome) {
    console.log(professor)
    setListaFiltrada([])
    var listaAux = [].concat([...listaDeAulas])
    var listaAUx2 = []
    setListaFiltrada([])
    console.log("listaFiltrada")
    console.log(listaFiltrada)

    for (let index = 0; index < listaAux.length; index++) {
      if (  (listaAux[index].data().professor == professor || typeof professor =="undefined" || professor.trim() == "") && 
            (listaAux[index].data().weekDay == diaDaSemana || diaDaSemana == 0)  && 
            (listaAux[index].data().alunos.includes(aluno) || aluno.trim() == "") && 
            (listaAux[index].data().tipo == tipo || tipo.trim() == "") && 
            (listaAux[index].data().nome == nome || nome.trim() == "") && 
            (listaAux[index].data().nivel == nivel || nivel.trim() == "")   ) {
        
        listaAUx2.push(listaAux[index])
      }
    }
    setListaFiltrada([])
    setListaFiltrada(listaAUx2) 
  }

  function filtraPorDiaDaSemana(diaDaSemana) {
    var listaAux = listaDeAulas.filter(
      element => element.data().weekDay == diaDaSemana
    )
    setListaFiltrada(listaAux)
  }

  useEffect(() => {
    verListaDeAulas("Great Padel Vila Verde")

    var listaAuxxx = [];
    for (let index = 0; index < alphabeto.length; index++) {
      for (let j = 0; j < alphabeto.length; j++) {
        var pafi = alphabeto[index] + alphabeto[j];
        listaAuxxx.push(pafi);
      }
    }
    setAplhabetoAA(listaAuxxx);
  }, [])

  const stylesTable = {
    "--bs-table-bg": "#ececf1",
  }
  
    const rendera = () => {
    return (
      <React.Fragment>
        <div className="page-content">
          <Container fluid>
            <div className="page-title-box">
              <Row className="align-items-center">
                <Col xs={7} sm={8} md={10} lg={10}>
                  <h6 className="page-title">{t("Aulas")}</h6>
                </Col>
                <Col md={2}>
                  <AdicionarAulasModal listaDeNomes={listaDeNomes} buttonLabel="Adicionar Aulas"></AdicionarAulasModal>
                </Col>
              </Row>
            </div>
            {/* <Row>
              <Button onClick={() => testeDOEmailDasExpire()}> testeDOEmailDasExpire</Button>
            </Row> */}
            {/* PESQUISA */}
            <Row style={{ paddingTop: "20px" }}>
              <Col md={1}>
                <h5>Professor</h5>
              </Col>
              <Col md={3}>
                <Input
                  onChange={e => {
                    setTreinador(e.target.value)
                  }}
                  value={trienador}
                  type="select"
                  name="select"
                  id="professorInput"
                >
                  {professores.map((elem, index) => {
                    return <option key={index}>{elem.nome}</option>
                  })}
                </Input>
              </Col>
              <Col md={1}>
                <h5>Aluno Email</h5>
              </Col>
              <Col md={3}>
                <Input
                value={aluno}
                  onChange={e => {
                    setAluno(e.target.value)
                  }}
                  type="text"
                  name="select"
                  id="alunoInput"
                >
                </Input>
              </Col>
              <Col md={1}>
                <h5>Tipo</h5>
              </Col>
              <Col md={3}>
              <Input
                  value={tipo}
                    onChange={e => {
                      setTipo(e.target.value)
                      
                    }}
                    type="select"
                    name="select"
                    id="tipoAula"
                  >
                    <option></option>
                    <option>Experimental</option>
                    <option>Academia</option>
                    <option>PAX</option>
                  </Input>
              </Col>
              
            
            </Row>
            <Row style={{ paddingTop: "20px" }}>
              <Col md={1}>
                <h5>Nivel</h5>
              </Col>
              <Col md={3}>
              <Input
                    value={nivel}
                    onChange={e => {
                      setNivel(e.target.value)
                    }}
                    type="select"
                    name="select"
                    id="nivelSelect"
                  >
                    {[""].concat(niveis).map((elem, index) => {
                      return <option key={index}>{elem}</option>
                    })}
                  </Input>
              </Col>
              
              <Col md={1}>
                <h5>Dia da semana</h5>
              </Col>
              <Col md={3}>
              <Input
                    value={weekday[diaDaSemana]}
                    onChange={e => {
                      setDiaDaSemana(
                        document.getElementById("weekdaySelect").selectedIndex
                      )
                      console.log(
                        document.getElementById("weekdaySelect").selectedIndex
                      )
                    }}
                    type="select"
                    name="select"
                    id="weekdaySelect"
                  >
                    {weekday.map((element, index) => {
                      return <option key={index}>{element}</option>
                    })}
                  </Input>
              </Col>
              <Col md={1}>
                <h5>Turma</h5>
              </Col>
              <Col md={3}>
              <Input
                    value={nome}
                    onChange={e => {
                      setNome(
                        document.getElementById("aulaInput22").value
                      )
                    }}
                    type="select"
                    name="aulaInput22"
                    id="aulaInput22"
                    placeholder="Nome da aula"
                  >
                    {aplhabetoAA.map((element, index) => {
                      return <option key={index}>{element}</option>
                    })}
                  </Input>
              </Col>
            <Row>
            <Col md={2}>
              <Button onClick={() => {
                filtraPorCampo(trienador, diaDaSemana, aluno, tipo,nivel,nome)
              }}  color="primary">Filtrar</Button>
              </Col>
            </Row>
            </Row>
            {/* TABELA */}
            <Row className='pt-5'>
              <Table bordered striped responsive className="myList" style={stylesTable} >
                  <thead>
                      <tr>
                          <th>Dia</th>
                          <th>Horário</th>
                          <th>Nr.A</th>
                          <th>Nível</th>
                          <th>Tipo</th>
                          <th>Professor</th>
                          <th>Turma</th>
                          <th>Estado</th>
                          <th>Campos</th>
                          <th>Ações</th>
                      </tr>
                  </thead>
                  <tbody>
                  {listaFiltrada.map((aulaSnap, index) => {
                    return (
                      <tr key={aulaSnap.id + "row"}>
                        <td>{weekday[aulaSnap.data().weekDay]}</td>
                        <td>{aulaSnap.data().horaInicial + "-" + aulaSnap.data().horaFinal}</td>
                        <td>{aulaSnap.data().alunos.length}</td>
                        <td>{aulaSnap.data().nivel}</td>
                        <td>{aulaSnap.data().tipo}</td>
                        <td>{aulaSnap.data().professor}</td>
                        <td>{aulaSnap.data().nome}</td>
                        <td>
                          {aulaSnap.data().isAtiva == true ? (
                            <span className="badge bg-success fs-6 px-3">Ativa</span>
                          ) : (
                            <span className="badge bg-danger fs-6 px-3">Inativa</span>
                          )}
                        </td>
                        <td>
                          {aulaSnap.data().campos.map((elem, index) => {
                            return <p>{convertCamps[elem]}</p>
                          })}
                        </td>
                        <td>
                          <div className="d-flex gap-3 align-items-center">
                            <Link className="btn btn-primary" key={aulaSnap.id + "nivel" + aulaSnap.data().nivel} to={"/aulas/" + aulaSnap.id}>
                              <ArrowRightCircle key={aulaSnap.id + "arrow" + aulaSnap.data().nivel}></ArrowRightCircle>
                            </Link>
                            <RemoveModal
                              key={aulaSnap.id + "alunos" + aulaSnap.data().alunos.length}
                              titulo="Tem a certeza que deseja remover esta aula?"
                              collection={aulasCollection}
                              docID={listaFiltrada[index].id}
                              removeLabel={"Remover"}
                            /> 
                            {/* <Button 
                              onClick={async() => {
                                var res = await removeAulasExperimentais(aulaSnap.id)
                                if (res) {
                                  alert("Removida")
                                } else {
                                  alert("Falhou")
                                }
                              }}
                            >Remove aula experimental e adiciona ao historico</Button> */}
                          </div>
                        </td>
                          {/* <td key={aulaSnap.id + "col" + aulaSnap.data().nivel} md={1}>
                          <Link key={aulaSnap.id + "nivel" + aulaSnap.data().nivel} to={"/aulas/" + aulaSnap.id}>
                            <ArrowRightCircle key={aulaSnap.id + "arrow" + aulaSnap.data().nivel}></ArrowRightCircle>
                          </Link>
                          </td> */}
                          {/* 
                            key={aulaSnap.id + "nivel" + aulaSnap.data().nivel}
                            aula={listaFiltrada[index].data()}
                            aulaId={aulaSnap.id} 
                            */}
                      </tr>
                    )
                  })}
                  </tbody>
              </Table>
            </Row>

        
          </Container>
        </div>
      </React.Fragment>
    )
  }
  return rendera()
  
}

export default AulasScreen
